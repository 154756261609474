html,
body {
  overflow-x: hidden;
}

@font-face {
  font-family: "OpenSans";
  src: url(../public/fonts/Open_Sans/static/OpenSans-Regular.ttf);
  font-weight: 400;
}

/* medium */
@font-face {
  font-family: "OpenSans";
  src: url(../public/fonts/Open_Sans/static/OpenSans-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: "OpenSans";
  src: url(../public/fonts/Open_Sans/static/OpenSans-SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: "OpenSans";
  src: url(../public/fonts/Open_Sans/static/OpenSans-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: "OpenSans";
  src: url(../public/fonts/Open_Sans/static/OpenSans-ExtraBold.ttf);
  font-weight: 900;
}

@font-face {
  font-family: "Lato";
  src: url(../public/fonts/Lato/Lato-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: "Lato";
  src: url(../public/fonts/Lato/Lato-Regular.ttf);
  font-weight: 400;
}

/* @font-face {
  font-family: "Lato";
  src: url(../public/fonts/Lato/Lato-Regular.ttf);
  font-weight: 400;
} */

/* medium */
@font-face {
  font-family: "Lato";
  src: url(../public/fonts/Lato/Lato-Thin.ttf);
  font-weight: 600;
}

@font-face {
  font-family: "Lato";
  src: url(../public/fonts/Lato/Lato-Black.ttf);
  font-weight: 700;
}

@font-face {
  font-family: "Lato";
  src: url(../public/fonts/Lato/Lato-Bold.ttf);
  font-weight: 900;
}


h1 {
  font-size: 45px !important;
}

h2 {
  font-size: 30px !important;
}

h1,
h2 {
  font-family: "Lato";
  font-weight: 300 !important;
}

h3 {
  font-size: 24px;
  font-weight: 300;
  font-family: "Lato";
}

h5 {
  font-size: 18px;
  font-weight: 200;
}

h6 {
  font-size: 14px;
}

h4,
h5,
h6 {
  font-family: "OpenSans";
  /* font-weight: 300 !important; */
}

/* h3{
    font-weight: 700 !important;
  } */
p,
label,
span,
li,
div,
input,
select {
  color: #000000;
  font-family: "OpenSans";
  font-size: 14px !important;
  font-weight: 400;
  line-height: 25px;
}

a {
  font-family: "Lato";
  text-decoration: none;
}

/* a:hover {
  color: #fff !important;
} */

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

iframe {
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #b1824f;
}

:focus,
:focus-visible {
  box-shadow: none;
  outline: none;
}

.side_spacing {
  padding: 0 7%;
}

.left_section_spacing {
  padding-left: 7% !important;
}

.right_section_spacing {
  padding-right: 7% !important;
}

.section_spacing {
  padding-top: 80px;
  padding-bottom: 80px;
}

.section_top_large {
  padding-top: 80px;
}

.section_top_small {
  padding-top: 40px;
}

.section_btm_small {
  padding-bottom: 40px;
}

.section_btm_large {
  padding-bottom: 80px;
}

.title_spacing {
  margin-bottom: 30px;
}

.section-title {
  font-size: 30px;
  position: relative;
  padding-left: 10px;
  margin-bottom: 30px;
  width: fit-content;
  line-height: 30px;
  text-transform: uppercase;
}

.section-title-bold {
  font-weight: 700;
}

.section-title::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0px;
  width: 1.5px;
  height: 100%;
  background: black;
}

.section-title-white::before {
  background: #fff;
}

.title-bold {
  font-size: inherit !important;
  font-weight: 700;
}

/* Header */

.header-hidden {
  opacity: 0;
}

.header-visible {
  opacity: 1;
}

.header_spacing {
  padding: 0 5%;
}

.header_section .nav-link {
  color: #000;
}

li.nav-item.link-border.active a {
  color: #b1824f;
}

li.nav-item.link-border a {
  font-size: 15px;
}

.link-border {
  position: relative;
}

.link-border::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1px;
  width: 1px;
  height: 22px;
}

.sticky {
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 1000;
}

.navbar {
  background: #FFFFFF;
  backdrop-filter: blur(7px);
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  border-radius: 0px;
  box-shadow: 0 0 7.5px #1a1a1a50;
}

.navbar-brand {
  display: block;
  margin: 0px;
}

.navbar-brand,
.navbar-brand-2 {
  height: 75px;
}

.navbar-brand img,
.navbar-brand-2 img {
  object-fit: contain;
}

.header-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  list-style: none;
  margin-bottom: 0px;
  padding-left: 0px;
}

li.nav-item {
  letter-spacing: 3px;
}

li.nav-item:last-child {
  border: none;
}

.header-btn {
  background: #cf9925;
  color: #fff;
  padding: 12px 20px;
  letter-spacing: 2px;
  text-decoration: none;
  white-space: nowrap;
  border: none;
  border-radius: 10px !important;
  line-height: 18px;
  border: 1px solid #cf9925;
  transition: 0.3s all ease;
}

.header-btn:hover {
  color: #cf9925;
  background: #fff;
  border: 1px solid #cf9925;
}

/* Banner Section */
/* Ensure the swiper container is positioned relatively */
.mobile-banner {
  display: none;
}

.ame-desc-wrapper.let-tran.translate-up {
  transform: translateY(80%);
}

.swiper-container {
  position: relative;
}

.banner_section {
  /* margin-top: 80px; */
  margin-top: 0px;
  position: relative;
}

/* Navigation buttons */
.custom-banner-next,
.custom-banner-prev {
  color: #fff;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.custom-banner-next {
  right: 10px;
  z-index: 5;
}

.custom-banner-prev i,
.custom-banner-next i {
  font-size: 17px;
}

.custom-banner-prev {
  left: 10px;
  z-index: 5;
}

.enquirynow_section {
  background-color: #f7f7f7;
}

.enquirynow-modal .section-title {
  display: none;
}

.enquirynow-modal .enquirynow_form {
  padding-left: 0px;
}

.enquirynow-modal .section-title {
  width: 95%;
}

.enquirynow-modal .form-btn {
  width: 40% !important;
}

.enquirynow-modal .custom-input {
  background-color: #fff !important;
}

.form-field::placeholder {
  color: #000;
}

.modal-close-btn {
  background-color: #fff;
  float: right;
  font-size: 12px;
  z-index: 5;
}

.modal-close-btn:focus {
  box-shadow: none !important;
  border: 1px solid;
}

.enquiry-modal {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  padding: 20px;
  background: #000;
}

.enquiry-modal-txt {
  text-decoration: none;
  font-family: "Lato";
  letter-spacing: 3px;
  font-size: 18px;
  color: #fff;
  transition: 0.3s all ease;
}

.enquiry-modal-txt:hover {
  color: #fff;
}

.slick-slide img {
  display: inline-block;
}

/* About Section */
.divider,
.about_section {
  background: #f7f7f7;
}

.abt-border {
  border-bottom: 1px solid #1a1a1a30;
  margin-bottom: 40px;
  padding-bottom: 40px;
}

/* SnapShot Section */
.snapshot_section {
  background: #f7f7f7;
  display: flex;
  align-items: self-start;
  gap: 30px;
}

.snapshot_section p {
  margin-bottom: 0px;
  /* line-height: 19px; */
}

.snapshot_section .counter {
  position: relative;
  text-wrap: nowrap;
}

.snapshot_section .counter::before {
  background: black;
  position: absolute;
  left: 0;
  bottom: -8px;
  content: "";
  height: 3px;
  width: 45px;
}

h2.counter {
  margin-bottom: 15px !important;
}

/* Thoughtful section */
.thoughtful_section {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}

.thoughtful-bg {
  background: #2c2c2c url(../public/images/thoughtful-bg.webp) fixed;
}

.thoughtful-icons {
  width: 80px;
  margin-bottom: 10px;
}

/* EarthOne Section */

section#overview {
  overflow: hidden;
  margin-top: 80px;
}

.earthone_title_spacing {
  margin-bottom: 15px;
}

.earthone_img {
  /* height: 673px; */
  height: 100%;
}

.earthone_img img {
  border-radius: 0 100px 0 0;
  object-position: -15px 0px;
}

.earthone_img_2 {
  height: 410px;
}

.earthone_img_3 {
  height: 290px;
}

.earthone-txt-container {
  padding: 60px 0px 60px 80px;
}

.earthone-txt-container-2 {
  padding: 60px 40px 60px 0px;
}

.earthone-subtile {
  position: relative;
  font-weight: 500;
  margin-bottom: 40px;
  text-transform: capitalize;
}

.earthone-subtile::after {
  background: black;
  position: absolute;
  left: 0;
  bottom: -19px;
  content: "";
  height: 3px;
  width: 115px;
}

.youtube-video {
  height: 450px;
  display: flex;
  justify-content: center;
}

.youtube-video iframe {
  border-radius: 10px;
  width: 65%;
  margin: 0 auto;
}

.counter-wrapper .row {
  row-gap: 20px;
}

.enquiry-modal2 {
  height: 120px;

  align-items: center;
}

.enquiry-modal2 button {
  color: #b1824f;
  width: fit-content;
  text-align: center;
  background: transparent;
  border: none;
}

/* Liveclose Section */

.liveclose_section {
  background-color: #eee;
}

.liveclose_section .earthone-txt-container {
  padding: 60px 40px 60px 0px;
}

.liveclose_img {
  /* height: 590px; */
  height: 100%;
}

.liveone-text {
  padding: 30px 0px;
}

.liveone-icons {
  width: 40px;
}

/* Project Hightlights */

.highlights-row {
  row-gap: 2rem;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, auto);
  gap: 15px;
}

.highlights-item {
  display: flex;
  gap: 10px;
  color: #fff;
}

.highlights-icon {
  width: 50px;
}

.highlights-item h5 {
  /* margin: 10px 0; */
  color: #fff;
}

.highlights-item p {
  margin: 0;
  color: #fff;
}

.highlights-custom-margin:nth-child(-n + 6) {
  margin-bottom: 24px;
}

/* Amenities section */
.amenities-carousel-container {
  /* margin-top: 20px; */
}

.amenities-carousel-container .swiper-wrapper {
  /* padding: 190px 0px; */
}

.amenities-carousel-container .swiper-slide.swiper-slide-active {
  /* transform: scale(1.5); */
  transition: all 0.5s ease;
  z-index: 5;
}

.amenities-carousel-container .swiper-slide.swiper-slide-prev,
.amenities-carousel-container .swiper-slide.swiper-slide-next {
  transition: all 0.6s ease-in-out;
  /* opacity: 0.3; */
}

.amenities_image {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  transition: all 0.5s ease;
}

.amenities_image a {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.amenities_image img {
  height: 85%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: all 0.7s ease;
}

.amenities-carousel-container .swiper-slide.swiper-slide-active .amenities_image img {
  height: 100%;
}

#amenities .ami-overlay {
  width: 0%;
  height: 100%;
  background: rgba(64, 58, 47, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  transition: all 1s;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  display: none;
}

#amenities .amenities_image:hover .ami-overlay {
  width: 100%;
}

.amenities-stick {
  transform: scale(0.75);
  transition: transform 0.3s ease;
}

.amenities-stick.scale-up {
  transform: scale(1);
}

.ame-desc-wrapper {
  transform: translateY(0);
  transition: transform 0.3s ease;
}

.ame-desc-wrapper.translate-up {
  transform: translateY(80%);
}

.ame-desc-wrapper.let-tran.translate-up {
  transform: translateY(75%);
}

/* Gallery Section */

.tabs-main-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.nav-pills .nav-link {
  color: #898989;
  padding: 9px 20px !important;
  border: 1px solid #ffffff;
  border-radius: 0;
  font-size: 15px;
  font-weight: 400;
  background: transparent !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: ease all 0.5s;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #000;
  border-bottom: 1px solid #000;
  cursor: pointer;
  border-radius: 0;
}

.nav-pills .nav-link:focus {
  color: #000;
}

.nav-pills .nav-link:hover,
.nav-pills .nav-link.active:hover {
  color: #000;
}

.amenities_section .carousel-nav-btns,
.gallery_section .carousel-nav-btns {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

/* .gallery_section .carousel_image img {
  height: 340px;
} */
.gallery_image {
  height: 300px;
}

.master-plan {
  display: flex;
  justify-content: center;
}

.master-plan img {
  max-width: 900px;
  object-fit: contain;
}

.custom-gallery-next,
.custom-gallery-prev {
  color: #fff;
  /* background-color: #000; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s all ease;
}

.sub-title {
  text-transform: none;
  text-align: center;
}

.custom-gallery-next i,
.custom-gallery-prev i {
  font-size: 20px;
}

.custom-gallery-next svg path,
.custom-gallery-prev svg path {
  transition: 0.3s all ease;
}

.custom-gallery-next svg,
.custom-gallery-prev svg {
  height: 35px;
  width: 35px;
}

.custom-gallery-next:hover svg path,
.custom-gallery-prev:hover svg path {
  fill: #ebb072;
  stroke: #ebb072;
}

/* Unit Plans */

.after-none::after {
  display: none;
}

/* Location Advantage */

.advantage_section {
  position: relative;
}

.loc-space {
  padding-left: 5rem;
}

.loc-space p {
  display: none;
}

.loc-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
}

.loc-bg-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: #080b2ce6;
  backdrop-filter: blur(1px);
  z-index: 2;
}

.location-wrapper {
  position: relative;
  z-index: 3;
  background: #F5F5F5F5;
  border-radius: 10px;
}

.image-container {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
}

.location-feature-wrapper {
  display: flex;
  flex-wrap: wrap;
  column-count: 3;
  gap: 20px;
}

.loc-feature {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 31.25%;
}

.loc-feature img {
  height: 40px;
  width: 40px;
  object-fit: contain;
}

.feature-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 22.8px;
  text-align: left;
  margin-bottom: 0px;
}

/* VTP Promise */
.promise_section {
  background-color: #f5f5f5;
}

.promise_card_inner {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.promise_card_inner img {
  width: 35px;
}

.promise_card p {
  margin: 0px;
}

.custom-margin:nth-child(-n + 4) {
  margin-bottom: 24px;
}

.live-custom-margin:nth-child(-n + 2) {
  margin-bottom: 24px;
}

.youtube-video-2 {
  height: 450px;
  margin: auto;
}

.youtube-video-2 iframe {
  border-radius: 10px;
}

/* Enquiry Now */

/* .enquirynow_form .header-btn {
  background: #000;
} */
.enquirynow_form {
  padding-left: 65px;
}

.form-check .form-check-input {
  margin-top: 5px;
}

.custom-input {
  background-color: #f7f7f7 !important;
  border: none;
  border-bottom: 1px solid #a6a7a9;
}

.custom-input:focus {
  box-shadow: none !important;
}

.form-btn {
  width: 25% !important;
  /* margin-top: 10px !important; */
}

span.error {
  color: #ff0033;
  font-size: 11px !important;
}

/* Footer */
.footer_section {
  margin-bottom: 16px;
}

.footer-scanner-img {
  width: 100px;
  height: 100px;
  margin: auto;
  margin-bottom: 10px;
}

.footer-scanner-img img {
  height: 100%;
  width: 100%;
}

.footer-text-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.footer_scan_text {
  text-align: center;
  font-weight: 500;
  padding-top: 1.5rem;
}

.footer_scan_text a {
  color: #000;
  text-decoration: underline !important;
  font-weight: 500 !important;
  transition: 0.3s all ease;
  font-size: 15px !important;
}

.footer_scan_text a:hover {
  color: #cf9925 !important;
}

.mahalogo {
  position: relative;
}

.mahalogo::before {
  position: absolute;
  background-image: url(../public/images/maha-rera.webp);
  content: "";
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 20px;
  height: 20px;
  top: 0;
  left: -25px;
}

.whatsapp-logo {
  position: fixed;
  left: 20px;
  bottom: 40px;
  width: 42px;
  z-index: 999999;
}

/* Mobile Btns  */

.mobile-btns {
  width: 100vw;
  position: fixed;
  padding: 20px;
  bottom: 0px;
  left: 0px;
  z-index: 500;
  text-align: center;
  display: none;
  background: #1a1a1a75;
  backdrop-filter: blur(2px);
  padding: 10px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.mobile-btns .bottom-btn {
  background: #3a333087;
  border: 2px solid #b1824f;
  border-radius: 15px;
  padding: 0px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 45px;
}

.mobile-btns .bottom-btn-lg {
  width: 120px;
}


/* Thank you page */

#thank-you-bg {
  background-image: url(../public/images/thank-you-bg.webp);
  background-size: cover;
  height: 100vh;
}

.bg-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-container {
  background: rgba(0, 0, 0, 0.8) no-repeat 50% 50%;
  width: 40%;
  padding: 20px;
  margin: 0px 15px;
}

.thankyou-logo {
  padding: 20px;
  height: 145px;
  margin: auto;
  width: 245px;
}

.user-details {
  width: 50%;
  margin: auto;
  text-align: left;
  margin-bottom: 20px;
}

.user-details p {
  color: #fff;
  margin-bottom: 5px;
  font-size: 15px;
}

.breadcrumb li a {
  color: #fff;
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #fff !important;
}

.breadcrumb-item+.breadcrumb-item::before {
  color: #fff !important;
}

.breadcrumb {
  margin-top: 10px;
  margin-bottom: 0px !important;
}

.home {
  display: flex;
  justify-content: center;
}

/* Error 404 page  */
.error_page {
  background: #4d4bdb;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error_page img {
  width: 500px !important;
  max-width: 100% !important;
  border-radius: 100px;
}

.navbar-toggler {
  border: none;
  background-color: transparent;
  padding: 0px;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-toggler svg {
  width: 30px;
  height: 30px;
}

@media (min-width: 1200px) {
  .banner_image {
    height: 100vh;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {

  .phone-btn,
  .navbar-nav li a {
    font-size: 12px !important;
  }

  .header-btn-container {
    gap: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .header_space {
    padding: 0px 20px;
  }

  .banner_image {
    height: auto;
  }

  .no-view {
    display: none;
  }

  .loc-space {
    padding-left: 4rem;
  }

  .navbar-brand,
  .navbar-brand-2 {
    height: 55px;
  }

  a.nav-link {
    padding: 3px;
  }

  .navbar-nav li,
  .header-btn-container li {
    font-size: 10px !important;
  }

  .side_spacing {
    padding: 0 5%;
  }

  .left_section_spacing {
    padding-left: 5% !important;
  }

  .right_section_spacing {
    padding-right: 5% !important;
  }

  .section_spacing {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .section_top_large {
    padding-top: 60px;
  }

  .section_top_small {
    padding-top: 30px;
  }

  .section_btm_small {
    padding-bottom: 30px;
  }

  .section_btm_large {
    padding-bottom: 60px;
  }

  .title_spacing {
    margin-bottom: 30px;
  }

  .link-border::after {
    height: 15px;
  }

  .earthone-txt-container {
    padding: 60px 0px 60px 40px;
  }

  .earthone_img img {
    object-position: -115px 0px;
  }

  /* .earthone_img {
    height: 776px;
  } */
  .enquiry-modal2 {
    height: 100px;
  }

  .earthone_img_2 {
    height: 430px;
  }

  .earthone_img_3 {
    height: 333px;
  }

  /* .liveclose_img {
    height: 803px;
  } */

  .youtube-video-2 {
    width: 75%;
  }

  .youtube-video,
  .youtube-video-2 {
    height: 200px;
  }


  .form-btn {
    width: 45% !important;
  }

  /* .amenities-carousel-container .swiper-wrapper {
    padding: 96px 0px;
  } */
  .footer_section {
    margin-bottom: 30px;
  }

  .amenities_image {
    height: 425px;
  }

}

@media screen and (max-width: 991px) {

  p,
  label,
  span,
  li,
  div,
  input,
  select {
    font-size: 13px !important;
    line-height: 20px;
  }

  h2 {
    font-size: 25px !important;
  }

  h3 {
    font-size: 18px !important;
  }

  .navbar-nav li,
  .header-btn-container li {
    font-size: 14px !important;
  }

  .section-title {
    margin-bottom: 15px;
  }

  .side_spacing {
    padding-left: 15px;
    padding-right: 15px;
  }

  .header_spacing {
    margin: 0px;
  }

  .banner_image {
    height: auto;
  }

  .left_section_spacing {
    padding-left: 15px !important;
  }

  .right_section_spacing {
    padding-right: 15px !important;
  }

  .section_spacing {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .section_top_large {
    padding-top: 40px;
  }

  .section_top_small {
    padding-top: 20px;
  }

  .section_btm_small {
    padding-bottom: 20px;
  }

  .section_btm_large {
    padding-bottom: 40px;
  }

  .title_spacing {
    margin-bottom: 15px;
  }

  .link-border::after {
    display: none;
  }

  .header-btn-container {
    display: none;
  }

  .enquiry-modal-txt {
    font-size: 15px;
  }

  .enquiry-modal2 {
    height: 80px;
  }

  .form-check .form-check-input {
    margin-top: 3px;
  }

  .thoughtful-icons {
    width: 50px;
  }

  .earthone-subtile {
    margin-bottom: 35px;
  }

  /* .earthone_img {
    height: 579px;
  } */
  .earthone_img_2 {
    height: 329px;
  }

  .earthone_img_3 {
    height: 235px;
  }

  .earthone-txt-container {
    padding: 30px 0px 30px 20px !important;
  }

  .earthone-txt-container-2 {
    padding: 30px 20px 30px 0px !important;
  }

  .youtube-video {
    height: 500px;
  }

  /* .liveclose_img {
    height: 603px;
  } */
  .liveone-text {
    padding: 20px 0px;
  }

  .live-custom-margin:nth-child(-n + 3) {
    margin-bottom: 22px;
  }

  .custom-margin-2:nth-child(-n + 3) {
    margin-bottom: 15px;
  }

  .highlights-icon {
    width: 45px;
  }

  .amenities_image {
    height: 305px;
  }

  .tabs-main-box {
    margin-top: 15px;
    margin-bottom: 1rem;
  }

  .footer-scanner-img {
    width: 115px;
  }

  .footer_section {
    margin-bottom: 65px;
  }

  .footer-text-container {
    gap: 0px;
    margin-bottom: 15px;
  }

  span.error {
    color: #ff0033;
    font-size: 10px !important;
  }

  .bottom-pop-enquire {
    display: none;
  }

  .whatsapp-logo {
    bottom: 76px;
  }

  .liveclose_section .earthone-txt-container {
    padding-left: 0px !important;
    padding-right: 20px !important;
  }

}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 30px !important;
  }

  h2 {
    font-size: 20px !important;
  }

  h5 {
    font-size: 15px;
  }

  .earthone-txt-container {
    padding: 30px 0px 30px 15px !important;
  }

  .earthone-subtile {
    margin-bottom: 25px;
  }

  .liveclose_section .earthone-txt-container {
    margin-left: 0px;
  }

  .youtube-video {
    height: 335px;
  }

  .youtube-video-2 {
    width: 100%;
  }

  .enquiry-modal-txt {
    font-size: 11px;
    line-height: 20px;
  }

  .custom-gallery-next,
  .custom-gallery-prev {
    width: 30px;
    height: 30px;
  }

  .custom-gallery-next i,
  .custom-gallery-prev i {
    font-size: 15px;
  }

  .nav-pills .nav-link {
    padding: 5px 12px !important;
    font-size: 13px;
  }

  .highlights-icon {
    width: 40px;
  }

  .enquiry-modal2 {
    height: 60px;
  }

  .enquirynow_form {
    padding-left: 0px;
  }

  .enquirynow_section .enquirynow_form {
    padding: 0px 15px;
  }

  .amenities-carousel-container .swiper-slide.swiper-slide-active,
  .amenities-carousel-container .swiper-slide.swiper-slide-prev,
  .amenities-carousel-container .swiper-slide.swiper-slide-next {
    transform: scale(1);
  }

  .amenities-carousel-container .swiper-wrapper {
    padding: 0px;
  }

  .gallery_image {
    height: 220px;
  }

  .header-btn {
    padding: 12px 20px;
  }
}

@media screen and (max-width: 551px) {
  .desktop-banner {
    display: none;
  }

  .banner_section {
    margin-top: 60px;
  }

  .navbar-brand,
  .navbar-brand-2 {
    height: 45px;
  }

  .mobile-banner {
    display: block;
  }

  .amenities_image {
    height: 180px;
  }

  .mobile-section {
    padding-left: 15px;
    padding-right: 15px;
  }

  .earthone_img {
    display: none;
  }

  .liveclose_img {
    display: none;
  }

  .nav-pills .nav-link {
    padding: 5px !important;
    font-size: 10px;
  }

  .header-btn {
    padding: 12px 20px;
  }

  .custom-banner-prev i,
  .custom-banner-next i {
    font-size: 15px;
  }

  .custom-banner-next,
  .custom-banner-prev {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .custom-banner-next {
    right: 3px;
  }

  .custom-banner-prev {
    left: 5px;
  }

  .custom-margin-2:nth-child(-n + 4) {
    margin-bottom: 15px;
  }

  .highlights-custom-margin:nth-child(-n + 7) {
    margin-bottom: 15px;
  }

  .enquirynow_section {
    padding: 0 15px;
  }

  .enquirynow_section .right_section_spacing {
    padding: 0 !important;
  }

  .enquirynow_section .enquirynow_form {
    padding: 0 !important;
  }

  li.nav-item {
    letter-spacing: 1px;
  }
}

/* EnquireNow.css */
.bottom-pop-enquire {
  position: fixed;
  right: 20px;
  bottom: 0px;
  width: 360px;
  background-color: #fff;
  padding: 10px 20px;
  z-index: 10;
  border: 3px solid #af8150;
  border-bottom: 0;
  overflow: hidden;
  transition: transform 0.5s ease;
}

.bottom-pop-enquire .custom-input {
  background-color: #fff !important;
}

.bottom-pop-enquire .enquirynow_form {
  padding-left: 0px;
}

.bottom-pop-enquire .form-btn {
  width: 100% !important;
}

.bottom-pop-enquire .section-title {
  padding: 5px;
}

.bottom-pop-enquire h5 {
  font-size: 16px;
}

hr.form-hr {
  color: #eee;
  border-width: 2px;
  margin: 10px 0px;
}

.toggle-button {
  border: 0;
  position: absolute;
  top: 3px;
  right: 5px;
  /* left: 0; */
  font-size: 24px;
  color: black;
  background: #fff;
  cursor: pointer;
}

.toggle-button img {
  width: 20px;
}

button.toggle-button.x-icon {
  font-size: 16px;
}

button.toggle-button.arrow-up-icon {
  width: 100%;
}

.bottom-pop-enquire .section-title {
  display: none;
}

.pop-up-header_section {
  display: flex;
  justify-content: center;
}

.pop-up-header img {
  height: 55px;
  width: auto;
}

/* Unit Plans */

.unit_section {
  background: #F5F5F5;
  position: relative;
}

.unit-bg {
  position: absolute;
  z-index: 2;
  bottom: 0px;
  object-fit: contain;
  height: 100%;
  width: auto;
}

.unit-left {
  left: 0px;
}

.unit-right {
  right: 0px;
}

table.unit-table {
  width: 100%;
  position: relative;
  z-index: 3;
  background-color: #fff;
  border-radius: 10px;
}

table.unit-table thead {
  background-color: #151515;
  border-radius: 10px;
}

table.unit-table thead tr {
  border-radius: 10px;
}

.border-rr {
  border-radius: 0px 10px 0 0;
}

.border-lr {
  border-radius: 10px 0 0 0;
}

table.unit-table thead th {
  color: #fff;
  font-size: 18px;
  font-weight: normal;
}

table.unit-table th,
table.unit-table td {
  text-align: center;
  padding: 15px 0px;
}

table.unit-table tr {
  border-bottom: 1px solid #F5F5F5;
}

table.unit-table tbody tr td {
  padding: 5px 0px;
  font-weight: 600;
  font-size: 15px;
}

.unit-title {
  margin-bottom: 30px;
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.unit-title h2 {
  margin-bottom: 1rem !important;
}

.unit-title p {
  color: #000;
  font-weight: 500;
  width: 75%;
  text-transform: none;
}

.cost-btn {
  border: none;
  font-size: 15px;
  color: #151515;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: fit-content;
  flex-wrap: nowrap;
  text-transform: uppercase;
  font-weight: 500 !important;
  margin: 0 auto;
  transition: 0.3s all ease;
}

.cost-btn svg {
  width: auto;
  height: 30px;
  transition: 0.3s all ease;
}

.cost-btn svg path {
  transition: 0.3s all ease;
}

.cost-btn.sold {
  cursor: not-allowed;
  color: red !important;
}

.cost-btn:hover,
.cost-btn:hover svg path {
  color: #cf9925;
  fill: #cf9925;
  stroke: #cf9925;
}

@media (max-width: 552px) {
  .whatsapp-logo {
    left: 10px;
    bottom: 60px;
  }

  .mobile-btns {
    display: block;
    padding: 15px;
  }

  nav.navbar.navbar-expand-lg {
    row-gap: 20px;
  }

  td button.header-btn {
    letter-spacing: 0.5px;
    padding: 5px;
  }

  table.unit-table tbody tr td {
    text-wrap: nowrap;
  }

  table.unit-table th {
    font-size: 12px;
  }

  table.unit-table tbody tr td {
    font-size: 11px;
  }
}

.after-none {
  max-width: 90%;
  margin: 0 auto;
}

.recaptcha-input {
  height: 0;
  width: 0;
  opacity: 0;
  margin: 0;
  padding: 0;
}

.fixed-btn {
  position: fixed;
  top: 50%;
  right: -53px;
  border-radius: 10px 10px 0px 0px !important;
  border-bottom: none !important;
  z-index: 9999;
  transform: rotate(90deg) scale(-1);
}

.disc-btn {
  cursor: "pointer";
  border: 'none';
  background: 'none';
  transition: 0.3s all;
}

.disc-btn:hover {
  color: #ebb072;
}

.phone-btn {
  border-radius: 25px !important;
  color: #000;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 15px;
  gap: 5px;
}

@media screen and (max-width: 550px) {
  .fixed-btn {
    display: none;
  }
}

.form-check-label {
  font-size: 12px !important;
}

.ytp-gradient-top,
.ytp-chrome-top.ytp-show-cards-title {
  display: none !important;
}

/* Amenities */

.wrapper {
  column-count: 3;
  gap: 20px;
  height: 100%;
}

.amenities-stick {
  position: relative;
  margin-bottom: 1.5rem;
  transform: scale(0.75);
  transition: 1s all ease;
  transition-delay: 0.5s;
}

.amenities-stick img {
  border-radius: 10px;
}

.ame-desc-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0px;
  display: flex;
  transform: translateY(0px);
  padding: 20px 0px;
  transition: all 1s ease;
  transition-delay: 1s;
}

p.ame-desc {
  color: #fff;
  background: linear-gradient(90deg, rgba(21, 21, 21, 0) 0%, rgba(21, 21, 21, 0.51) 25.9%, rgba(21, 21, 21, 0.85) 51%, rgba(21, 21, 21, 0.95) 100%);
  height: fit-content;
  width: fit-content;
  padding: 10px 10px 10px 45px;
  font-size: 24px !important;
  text-transform: capitalize;
}

.before-0::before {
  display: none !important;
}

.form-control {
  padding-left: 0px;
  padding-right: 0px;
}

.form-select {
  padding-left: 0px;
}

@media (max-width: 1024px) {
  .youtube-video {
    height: 400px;
  }

  .enquirynow_form {
    padding-left: 5px;
  }

  .ame-desc-wrapper.translate-up {
    transform: translateY(70%);
  }

  .ame-desc-wrapper.let-tran.translate-up {
    transform: translateY(65%);
  }

  .loc-feature {
    width: 30.25%;
  }

  .loc-space {
    padding-left: 1rem;
  }

  .whatsapp-logo {
    bottom: 65px;
  }

  p.ame-desc {
    font-size: 13px !important;
  }
}

.brand-2 {
  display: none;
}

@media (max-width: 991px) {
  span.mahalogo {
    font-size: 11px !important;
  }

  .brand-2 {
    display: flex;
    margin-left: 15px !important;
  }

  .hidefortab {
    display: none !important;
  }

  .whatsapp-logo {
    position: static;
    height: 35px;
    width: 35px;
    display: block;
  }

  .whatsapp-logo img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  /* .location-wrapper .row {
    flex-direction: column-reverse;
  } */

  .location-feature-wrapper {
    column-count: 3;
  }

  .loc-feature {
    width: 30.5%;
  }

  .loc-space {
    padding-left: 0rem;
  }

  .image-container {
    align-items: center;
    padding-bottom: 0px;
  }

  .image-container .cost-btn {
    width: 100%;
    justify-content: end;
    padding-right: 15px;
    height: 18px;
  }

  .ame-desc-wrapper.translate-up {
    transform: translateY(70%);
  }

  .ame-desc-wrapper.let-tran.translate-up {
    transform: translateY(65%);
  }

  .abt-border {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  section#snapshot {
    flex-direction: column;
    gap: 5px;
  }

  .mob-foot-list {
    display: flex;
    list-style: none;
    margin: 0px;
    padding: 0px;
    justify-content: space-evenly;
    gap: 10px;
  }

  li.mob-foot-li {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  li.mob-foot-li * {
    color: #FFF !important;
  }

  li.mob-foot-li img {
    height: 18px;
    width: 18px;
  }

  .border-li {
    display: block;
    height: 100%;
    width: 2px;
    background-color: #fff;
  }

}

@media (max-width: 768px) {
  .loc-feature {
    width: 30.46%;
  }

  p.ame-desc {
    font-size: 12px !important;
  }
}

@media (max-width: 552px) {
  .wrapper {
    column-count: 2
  }

  .amenities-stick {
    margin-bottom: 1rem;
    height: 100px;
  }

  .amenities .amenities-stick {
    height: 150px;
  }

  .location-wrapper .row {
    flex-direction: column-reverse;
    padding: 0px 0 30px;
  }

  .youtube-video {
    height: 250px;
  }

  .loc-space {
    padding-left: 0rem;
  }

  section#snapshot .row {
    justify-content: flex-start;
    row-gap: 10px;
  }

  section.enquirynow_section iframe {
    height: 400px;
    border-radius: 10px;
  }

  section.enquirynow_section .row.rev {
    flex-direction: column-reverse;
    padding-bottom: 30px;
  }

  .footer-text-container {
    flex-direction: column;
  }

  .earthone-subtile {
    margin-bottom: 30px;
  }

  .earthone-subtile::after {
    bottom: -12px;
  }

  table.unit-table thead th {
    font-size: 12px;
  }

  .cost-btn {
    font-size: 11px;
    gap: 2px;
    padding: 0px;
  }

  .cost-btn svg {
    height: 20px;
  }

  .feature-title {
    font-size: 14px;
  }

  .location-feature-wrapper {
    gap: 25px;
  }

  .loc-feature {
    width: 45.9%;
  }

  .snapshot_section p {
    font-size: 12px !important;
  }

  .mahalogo::before {
    top: -5px;
  }

  p.ame-desc {
    padding: 5px 5px 5px 25px;
    font-size: 8px !important;
  }

  .ame-desc-wrapper.let-tran.translate-up,
  .ame-desc-wrapper.translate-up {
    transform: translateY(60%);
  }

  .abt-border {
    margin-bottom: 15px !important;
    padding-bottom: 15px;
    margin: 0;
  }

  .abt-border * {
    padding: 0px;
  }

  .abt-border h2 {
    padding-left: 10px;
  }

  .youtube-video iframe {
    width: 100%;
    margin: 0 auto;
  }

  .youtube-video {
    height: 185px;
  }

  section#overview {
    margin-top: 0px;
  }

  .unit-title p {
    width: 100%;
    max-width: 100%;
  }

  section#highlights h2 {
    margin-bottom: 30px;
  }

  .mob-foot-list svg {
    height: 20px;
    width: 20px;
  }

  .pre-con {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .pre-con span {
    line-height: 16px;
  }

  .pre-con svg {
    height: 35px;
  }

  .mob-foot-list .header-btn {
    padding: 7px 10px;
  }

  section#footer .row {
    row-gap: 20px;
  }

  .footer-text-container {
    gap: 0px;
    margin-bottom: 0;
  }

  .tabs-main-box {
    margin-top: 0;
  }

  .youtube-video-2 {
    height: 185px;
  }

  section.walkthrough .row {
    row-gap: 25px;
  }

}

a.header-btn.loc.m-0 {
  line-height: 5px;
  padding: 1px 7px;
  display: flex;
  align-items: center;
  gap: 1px;
  border-radius: 5px !important;
}

a.header-btn.loc.m-0 svg {
  height: 25px;
  transition: 0.3s all ease;
}

a.header-btn.loc svg path {
  fill: #fff;
  stroke: #fff;
  transition: 0.3s all ease;
}

a.header-btn.loc.m-0:hover svg path {
  fill: #cf9925;
  stroke: #cf9925;
}

/* Unit Price */
.unit-price .row-gap {
  row-gap: 25px;
}

.price-box {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.box-image {
  position: relative;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.box-image img {
  height: 100%;
  width: 100%;
  filter: blur(5px);
}

.box-cta {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

img.popup-img {
  height: 100%;
  width: 100%;
}

@media (max-width: 552px) {
  #welcomeModal .modal-dialog {
    margin-right: 20px;
    margin-left: 20px;
  }
}

#welcomeModal .btn-close {
  position: absolute;
  right: -5px;
  top: -10px;
  background-color: #fff;
  opacity: 1;
  border-radius: 50%;
  padding: 5px;
}

#welcomeModal .modal-body {
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

h5.pop-up-header {
  display: flex;
  justify-content: center;
  width: 100%;
}

button.btn-close.modal-close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  background: transparent var(--bs-btn-close-bg) center/1.5em auto no-repeat;
}

.pl-0 {
  padding-left: 0;
}

.submit-btn {
  border-radius: 25px !important;
}

.modal-content {
  border-radius: 15px;
  background-color: #F8F8F8;
}

.custom-input {
  background-color: #fff !important;
  padding: 10px;
  border-radius: 10px !important;
  min-height: 40px;
}

.form-select {
  background-image: none;
}

@media (max-width: 552px) {
  .modal-dialog {
    margin-left: 25px;
    margin-right: 25px;
  }

  div#cf-turnstile {
    overflow: hidden;
  }
}


:root {
  --animate-color: rgb(26, 80, 140);
}

.no1_sec_wrap {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-image: url('../public/images/building-bg.webp');
  background-position: center;
  background-size: cover;
  height: 80vh;
}

.no1_copy {
  position: absolute;
  right: 0;
  max-height: 20vw;
  top: 50%;
  margin-top: -10vw;
  margin-right: 13vw;
  width: auto;
}

img.no1_building.aos-init.aos-animate {
  width: 100%;
  height: 80vh;
  object-fit: revert;
}

text#_ {
  font-size: 700px;
}

.animate_path2 {
  font-weight: 700;
}

.svg_no1 {
  width: 25vw;
  position: absolute;
  left: 0;
  bottom: -1.6vw;
  margin-left: 9vw;
}

.svg_no1 .animate_path1,
.svg_no1 .animate_path2 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke: var(--animate-color);
  fill: #fff;
  stroke-miterlimit: 10;
  stroke-width: 2;
  stroke-dasharray: 2156;
  stroke-dashoffset: 2156;
  animation: line-animation 5s ease-in-out infinite, pause-animation 5s ease-in-out 1 3s;
}


@keyframes line-animation {
  0% {
    stroke-dashoffset: 2156;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes pause-animation {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 2156;
  }
}

@media (max-width: 1500px) {
  .no1_sec_wrap {
    height: 50vh;
    background-position: right;
  }

  img.no1_building.aos-init.aos-animate {
    height: 50vh;
    width: 80%;
  }

}

@media (max-width: 1024px) {
  .no1_sec_wrap {
    height: 30vh;
    background-position: right;
  }

  img.no1_building.aos-init.aos-animate {
    height: 30vh;
    width: 80%;
  }

}

@media (max-width: 991px) {
  .no1_sec_wrap {
    height: 30vh;
    background-position: right;
  }

  img.no1_building.aos-init.aos-animate {
    height: 30vh;
    width: 80%;
  }

}

@media (max-width: 550px) {
  .no1_sec_wrap {
    height: 30vh;
  }

  .no1_copy {
    right: 0;
    max-height: 40vw;
    top: 30%;
    margin-top: -10vw;
    margin-right: 6vw;
    width: auto;
  }

  img.no1_building.aos-init.aos-animate {
    height: 30vh;
  }

  .banner_image {
    height: auto;
  }
}

.footer-new-desc {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #F5F5F5;
  position: relative;
  z-index: 3;
}

.footer-new-desc * {
  text-align: center;
  position: relative;
  z-index: 3;
  color: #fff;
}

.footer-new-desc h3 {
  font-size: 14px !important;
  line-height: 20px;
}

.footer-new-desc p {
  font-size: 12px !important;
}

.footer-new-desc {
  padding-top: 30px;
  padding-bottom: 30px;
}

.footer-new-desc-bg-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: #080b2ce6;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  z-index: 2;
}

.footer-new-desc-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
}

.xl-banner {
  display: none;
}

@media (min-width: 1650px) {
  .desktop-banner {
    display: none;
  }

  .xl-banner {
    display: block;
  }
}

.const {
  border: 1px solid #1a1a1a30;
}

.shot iframe {
  height: 350px;
  width: 100%;
  border-radius: 10px;
}

@media (max-width: 552px) {
  .shot iframe {
    height: 200px;
  }
}


#township {
  display: flex;
  align-items: center;
}

.township-row {
  row-gap: 50px;
}

.th-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;

}

.township-highlights {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #151515;
  padding: 25px;
}

.township-ul {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.township-box {
  width: 100%;
  max-width: 250px;
  height: 100%;
  text-align: center;
  border-radius: 5px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.township-box p {
  text-align: center;
  display: flex;
}

@media (max-width: 552px) {
  .township-row {
    row-gap: 30px;
  }

  .th-wrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    margin-top: 10px;
  }

  .township-ul {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .pmob-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .township .location-wrapper .row {
    padding-bottom: 0px;
  }

  img.no1_building.aos-init.aos-animate {
    height: 30vh;
    width: 55%;
  }
}

.onlyatvtp_section {
  /* background-image: url('../public/images/onlyatvtp.webp'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: fixed;
  position: relative;
  background: #f5f5f5;
}

/* .onlyatvtp_section::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  background: #1a1a1a90;
} */

.oav-wrapper {
  position: relative;
  z-index: 1;
  /* padding: 25px; */
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.oav-row {
  row-gap: 15px;
  justify-content: center;
}

.oav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
  padding: 25px 15px;
  border: 2px dashed #151515;
  border-radius: 10px;
  height: 100%;
}

.oav-icon {
  height: auto;
  width: auto;
  object-fit: contain;
}

.oav-title {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0px;
  text-align: center;
}

@media (max-width: 552px) {
  .oav-wrapper {
    padding: 25px 15px;
  }

  .oav-item {
    gap: 10px;
    padding: 10px;
  }

  .oav-title {
    text-align: center;
  }
}

@media (max-width: 552px) {
  section#onlyatvtp .side_spacing {
    padding-right: 0;
    padding-left: 0;
  }
}

.unit-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.price-tag {
  background: #EADBC4;
  padding: 15px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-items: center;
  position: relative;
  width: 260px;
}

.price-tag::before,
.price-tag::after {
  content: '';
  z-index: 2;
  position: absolute;
  top: -1px;
  bottom: 0;
  height: 102%;
  width: 20px;
  background: url("../public/images/price-tag-elem.svg");
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}

.price-tag::before {
  right: -18px;
}

.price-tag::after {
  top: -1px;
  left: -18px;
  transform: scale(-1);
}

.tag-title {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tag-title .separator {
  width: 50%;
  height: 1px;
  background: #fff;
  margin: 2px 0px;
}

.price-tag p {
  font-size: 16px !important;
  width: 100%;
  text-align: center;
}

.price-tag p span {
  font-size: inherit !important;
}

.unit-ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 20px;
}

@media (max-width:1024px) {
  .price-tag {
    width: 535px;
  }

  .unit-ul {
    flex-wrap: wrap;
    justify-content: center;
  }

  .unit-li {
    max-width: 280px;
  }
}

@media (max-width: 991px) {
  .price-tag {
    width: 425px;
    right: 20px;
  }

  .unit-li {
    max-width: 230px;
  }

}

@media (max-width: 552px) {
  .price-tag {
    position: absolute;
    top: 0;
    right: 15px;
    padding: 10px 5px;
    width: 150px;
  }

  .price-tag p {
    font-size: 14px !important;
  }

  .price-tag::before,
  .price-tag::after {
    top: 0px;
    height: 100%;
  }

  .price-tag::before {
    right: -15px;
  }

  .unit-li {
    max-width: 100%;
  }

}